import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(IsBetween);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type TDate = string | number | Date | dayjs.Dayjs | null | undefined;

function add(date: TDate, number: number, unit: dayjs.ManipulateType) {
  return dayjs(date).add(number, unit);
}

function subtract(d1: TDate, d2: number, period: dayjs.ManipulateType) {
  return dayjs(d1).subtract(d2, period);
}

function set(date: TDate, prop: dayjs.UnitType, value: number) {
  return dayjs(date).set(prop, value);
}

function format(date: TDate = new Date(), formatStr = 'MMM D, YYYY', isUTCEnabled = false) {
  return isUTCEnabled ? dayjs(dayjs(date).utc(true).format()).format(formatStr) : dayjs(date).format(formatStr);
}

function getDeviceTimeZone() {
  return dayjs.tz.guess();
}

function getDurationDifference(d1: TDate, d2: TDate, asa: dayjs.UnitType = 'month', float = true) {
  return dayjs(d1).diff(d2, asa, float);
}

function getInterval(start: TDate, end: TDate) {
  const n = getDurationDifference(end, start, 'day', false);
  if (n <= 0) return [];
  const arr = [dayjs(start)];
  for (let i = 0; i < n; i++) {
    arr[arr.length] = arr[arr.length - 1].add(1, 'day');
  }

  return arr;
}

function startOf(date: TDate = new Date(), off: dayjs.OpUnitType = 'week') {
  return dayjs(date).startOf(off);
}

function endOf(date: TDate = new Date(), off: dayjs.OpUnitType = 'week') {
  return dayjs(date).endOf(off);
}

function getDateOfMonth(date: TDate) {
  return dayjs(date).date();
}

function isDateSameOrAfter(d1: TDate, d2: TDate, t: dayjs.OpUnitType = 'date') {
  return dayjs(d1).isSameOrAfter(d2, t);
}

function isDateSameOrBefore(d1: TDate, d2: TDate, t: dayjs.OpUnitType = 'date') {
  return dayjs(d1).isSameOrBefore(d2, t);
}

function isInBetween(
  d: TDate,
  { startTime, endTime }: { startTime: TDate; endTime: TDate },
  t: dayjs.OpUnitType = 'milliseconds'
) {
  return dayjs(d).isBetween(startTime, endTime, t);
}

function checkGreater(firstDateStr: TDate, secondDateStr: TDate = new Date()) {
  return dayjs(firstDateStr).isAfter(secondDateStr, 'date');
}

function getDayNumberOfWeek(dateStr = new Date().toString()) {
  return dayjs(dateStr).day();
}

function getDateOfDay(dateStr: TDate, dayNumber: number) {
  return dayjs(dateStr).day(dayNumber);
}

function getTimeInMilliSecond(d: TDate = new Date()) {
  return dayjs(d).valueOf();
}

function getDateByMilliSecond(milliSecond: number) {
  return new Date(milliSecond * 1000);
}

function utcDateString(d: TDate) {
  return dayjs(d).format();
}

function getDayNumberByDateOrWeek(date: TDate, myFormat: 'day' | 'month' | 'date' = 'day') {
  // if myFormat == day, we would like to return day of the week
  // or else we will return the date of the month
  if (myFormat === 'day') {
    return dayjs(date).isoWeekday();
  }

  return parseInt(format(date, 'DD'), 10);
}

function timeZoneToUtc(date: TDate, timeZone = 'America/New_York') {
  return dayjs.tz(dayjs(date), timeZone);
}

function utcToTimezone(date: TDate, timeZone = 'America/New_York') {
  return dayjs.tz(date, timeZone);
}

function getFormattedDate(date: TDate, formatStr = 'M/D/YYYY', timeZone = getDeviceTimeZone(), isTimeStamp = false) {
  // if don't get timezone set current timezone of the user
  // let timeZone = oTimeZone;
  // if (!timeZone) timeZone = getDeviceTimeZone();

  const utcDate = timeZoneToUtc(date, timeZone);

  if (isTimeStamp) {
    return getTimeInMilliSecond(utcDate);
  }

  return format(utcDate, formatStr);
}

function isSame(d1: TDate, d2: TDate, t: dayjs.UnitType = 'date') {
  return dayjs(d1).isSame(d2, t);
}

function isBefore(d1: TDate, d2: TDate, t: dayjs.UnitType = 'date') {
  return dayjs(d1).isBefore(d2, t);
}

function isAfter(d1: TDate, d2: TDate, t: dayjs.UnitType = 'date') {
  return dayjs(d1).isAfter(d2, t);
}

function isToday(d: TDate, t: dayjs.UnitType = 'date') {
  return dayjs(d).isSame(now(), t);
}

function now(isUTC = false) {
  return isUTC ? dayjs().utc() : dayjs();
}

function doubleNumber(num: string) {
  const x = parseInt(num, 10) > 9 || num === '00' ? `${num}` : `0${num}`;

  if (parseInt(x, 10) === 0) {
    return '00';
  }
  return x;
}

function getConvertedToLocalStamp(date: string, time: string) {
  let [xHours, xMinutes] = time.split(':');
  const [month, day, year] = format(date, 'MM/DD/YYYY').split('/');
  let minutes = '00';
  let hours = xHours;

  if (xMinutes) {
    xMinutes = xMinutes.toUpperCase();
    if (xMinutes.indexOf('PM') > -1) minutes = xMinutes?.split('PM').join('');
    if (xMinutes.indexOf('AM') > -1) minutes = xMinutes.split('AM').join('');
  } else {
    xHours = xHours.toUpperCase();
    if (xHours.indexOf('PM') > -1) hours = xHours?.split('PM').join('');
    if (xHours.indexOf('AM') > -1) hours = xHours.split('AM').join('');
  }

  if (time.toUpperCase().indexOf('PM') > -1) {
    if (parseInt(xHours, 10) + 12 < 24) {
      hours = `${parseInt(xHours, 10) + 12}`;
    }
  }

  const strDateTime = `${year}-${month}-${day} ${doubleNumber(hours)}:${doubleNumber(minutes)}`;
  return convertEstToLocalTime(strDateTime, 'YYYY-MM-DD HH:mm');
}

function parseFromEstStrToEst(timeDateStamp: TDate) {
  return dayjs(timeDateStamp).tz('America/New_York');
}

function getDeviceTimeInEST() {
  return dayjs.tz(dayjs(), 'America/New_York');
}

function parse(str: TDate, formatStr = '') {
  if (formatStr) {
    return dayjs(str, formatStr);
  }
  return dayjs(str);
}

function parseToUtc(d: TDate) {
  return dayjs.utc(d);
}

function parseEstToUtc(d: TDate, _?: string) {
  return dayjs.tz(dayjs(d), 'America/New_York').tz('utc');
}

function parseEstToLocalTimeZone(d: TDate) {
  return dayjs.tz(parseToUtc(d), getDeviceTimeZone());
}

function getDateFromWeekDay(n: number) {
  return getDateOfDay(now().toISOString(), n).toISOString();
}

function getDateFromMonthDayNumber(n: number) {
  return set(now().toISOString(), 'date', n).toISOString();
}

function getMonthlyPaymentDate(date = now().toISOString(), stringReq = true) {
  // if date is above 28 make it 28
  let nDate = parse(date);
  const currentDateValue = nDate.format('D');
  if (parseInt(currentDateValue, 10) > 28) {
    nDate = nDate.date(28);
  }

  return stringReq ? nDate.toISOString() : nDate;
}

function convertEstToUtc(time: string, formatStr = 'HH:mmA') {
  const estTime = dayjs.tz(time, formatStr, 'America/New_York');
  const utcTime = estTime.utc();
  return utcTime;
}

function convertUtcToLocalTime(utcTime: string, formatStr = 'HH:mmA') {
  const localTime = dayjs.tz(utcTime, formatStr, 'UTC').tz(dayjs.tz.guess());
  return localTime;
}

function convertEstToLocalTime(time: string, formatStr = 'HH:mmA') {
  const utcTime = convertEstToUtc(time, formatStr);
  const localTime = convertUtcToLocalTime(utcTime.format(), formatStr);
  return localTime;
}

const dateTime = {
  now,
  add,
  subtract,
  set,
  startOf,
  endOf,
  getDateOfMonth,
  checkGreater,
  isDateSameOrAfter,
  isDateSameOrBefore,
  isInBetween,
  getDayNumberOfWeek,
  getDateOfDay,
  getTimeInMilliSecond,
  getDateByMilliSecond,
  utcDateString,
  format,
  getDurationDifference,
  getDayNumberByDateOrWeek,
  getInterval,
  timeZoneToUtc,
  parseEstToLocalTimeZone,
  getDeviceTimeZone,
  getFormattedDate,
  getConvertedToLocalStamp,
  isSame,
  isBefore,
  isAfter,
  isToday,
  utcToTimezone,
  parseFromEstStrToEst,
  parse,
  parseToUtc,
  getDeviceTimeInEST,
  parseEstToUtc,

  convertEstToUtc,
  convertUtcToLocalTime,
  convertEstToLocalTime,

  getDateFromWeekDay,
  getDateFromMonthDayNumber,
  getMonthlyPaymentDate,
  doubleNumber,
};

export default dateTime;

// window.dateTime = dateTime;
// window.dayjs = dayjs;
